import authService from "../services/authService";
import axios from "../extension/axios";
import moment from "moment";

const salesAPI = {
  getAllSalesTransactions: async () => {
    let response;

    try {
      response = await axios.get(
        `${authService.getUnitUrl()}sales/transactions`
      );
    } catch (error) {
      console.log(error);
      let message = "There was an error retrieving all sales transactions.";
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
  getFilteredSalesTransactions: async (startTime, endTime) => {
    const requestBody = {
      start_time: moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
    };

    let response;

    try {
      response = await axios.get(
        `${authService.getUnitUrl()}sales/transactions`,
        requestBody
      );
    } catch (error) {
      console.log(error);
      let message = "There was an error retreiving all sales transactions.";
      return { status: false, error: message };
    }

    return { status: true, data: response };
  },

  getSalesTransactionFootage: async (cameras, timestamp, duration = 20) => {
    const startTime = new Date(timestamp);
    const endTime = new Date(timestamp);

    startTime.setSeconds(startTime.getSeconds() - duration);
    endTime.setSeconds(endTime.getSeconds() + duration);

    const videoData = await Promise.all(
      cameras.map(async (camera) => {
        const date = moment(timestamp, "MM/DD/YYYY HH:mm:ss").format(
          "YYYYMMDD"
        );
        const baseURL = `${authService.getUnitUrl()}/media/${
          camera.mac
        }/${date}/timelapse.m3u8`;

        const params = new URLSearchParams({
          start_timestamp: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          end_timestamp: moment(endTime).format("YYYY-MM-DDTHH:mm:ss"),
        });

        const url = `${baseURL}?${params.toString()}`;

        try {
          const response = await axios.get(url);
          return { ...camera, url, file: response.data };
        } catch (error) {
          return { ...camera, url, file: null };
        }
      })
    );

    return { status: true, data: videoData };
  },
  uploadSalesTransactions: async (posProvider, file) => {
    const requestFormData = new FormData();
    requestFormData.append("file", file);
    requestFormData.append("pos_provider", posProvider);

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}sales/upload`,
        requestFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.log(error);
      const message = error.response.data.error;
      return { status: false, error: message };
    }

    return { status: true, data: response.data };
  },
};

export default salesAPI;
