import React, { useEffect, useRef } from "react";
import EventList from "../components/EventList";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReportIcon from "@mui/icons-material/Report";
import LiveVideoPlayer from "../components/LivePlayer";
import authService from "../services/authService";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let cameraList = useSelector((state) => state.camera.cameraList);
  let eventListControl = useRef();

  useEffect(() => {}, [navigate, dispatch]);

  const createLiveUrl = (macOfCamera) => {
    const url = `${authService.getUnitUrl()}media/live/${macOfCamera}/output.m3u8`;

    return url;
  };

  function navNoti(notification, filterModel) {
    navigate("/events", {
      state: {
        filterModel: filterModel,
        notification: notification,
      },
    });
  }

  return (
    <div className="page-container flex flex-col h-full p-3 md:flex-row gap-4">
      <div className="flex flex-1 flex-row flex-wrap gap-2 content-start">
        {cameraList &&
          cameraList.length > 0 &&
          cameraList.map((camera, index) => {
            return (
              <div
                className="bg-white dark:bg-neutral-500 border dark:border-neutral-400 p-2 w-72 h-64"
                key={index}
              >
                <div className="flex mb-2">
                  <div className="w-full text-center">
                    <h3>{camera.name}</h3>
                  </div>
                  {!camera.ishealthy && (
                    <div
                      className="text-red-700 p-px"
                      title={`Camera: ${camera.name} has a issue.`}
                    >
                      <ReportIcon />
                    </div>
                  )}
                </div>
                <div
                  onClick={() =>
                    navigate("/live", {
                      state: {
                        mac: camera.mac,
                      },
                    })
                  }
                >
                  <LiveVideoPlayer
                    playerId={`player_${camera.mac}`}
                    videoUrrl={createLiveUrl(camera.mac)}
                    cameraName={camera.name}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <div className="w-full min-w-96 md:w-1/2 xl:w-1/3">
        <EventList
          ref={eventListControl}
          handleNotificationClick={navNoti}
          defaultIntent="monitoring"
        />
      </div>
    </div>
  );
}
