import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Skeleton,
  Tag,
  message,
} from "antd";
import {
  DeleteFilled,
  DislikeFilled,
  EditFilled,
  LikeFilled,
  SaveFilled,
  UndoOutlined,
  WarningFilled,
} from "@ant-design/icons";

import NotificationPlayer from "../components/NotificationPlayer";
import EventList from "../components/EventList";
import {
  deleteNotification,
  saveUserFeedback,
} from "../store/notification/notificationAction";

export default function Events() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const intentList = useSelector(
    (state) => state.lookup.intentList,
    shallowEqual
  );
  const actionTypes = useSelector(
    (state) => state.lookup.actionTypes,
    shallowEqual
  );
  const loadingNotification = useSelector(
    (state) => state.notification.showLoadingNotification
  );

  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notificationEditMode, setNotificationEditMode] = useState(false);
  const [notificationModified, setNotificationModified] = useState(false);
  const [notificationHasFeedback, setNotificationHasFeedback] = useState(false);

  const [messageAPI, MessageContextHolder] = message.useMessage();
  const eventListControl = useRef();

  useEffect(() => {
    const newNotificatioSelected = () => {
      setNotificationEditMode(false);

      if (selectedNotification.feedback_description) {
        setNotificationHasFeedback(true);
      } else {
        setNotificationHasFeedback(false);
      }

      form.setFieldsValue({
        actionEvents: selectedNotification.actionEvents.map(
          (action) => action.id
        ),
        feedbackDescription: selectedNotification.feedback_description
          ? selectedNotification.feedback_description
          : selectedNotification.description,
      });
    };

    if (selectedNotification) {
      newNotificatioSelected();
    }
  }, [selectedNotification, form]);

  const setMainNotification = (notification) => {
    setSelectedNotification(notification);
  };

  const handleToggleEditNotificationMode = () => {
    if (notificationModified) {
      messageAPI.warning("Save or Undo Changes Before Toggling Edit Mode");
    } else {
      setNotificationEditMode(!notificationEditMode);
    }
  };

  const handleUndoNotificationChanges = () => {
    form.setFieldsValue({
      actionEvents: selectedNotification.actionEvents.map(
        (action) => action.id
      ),
      feedbackDescription: selectedNotification.description,
    });
    setNotificationModified(false);
  };

  const handleApproveNotification = () => {
    const notification = { ...selectedNotification };

    dispatch(saveUserFeedback(notification, true)).then((result) => {
      if (result.status) {
        messageAPI.success("Approved Generated Event Actions and Description");
      }
    });
  };

  const handleDisapproveNotification = () => {
    const notification = { ...selectedNotification };

    dispatch(saveUserFeedback(notification, false)).then((result) => {
      if (result.status) {
        messageAPI.success(
          "Disapproved Generated Event Actions and Description"
        );
      }
    });
  };

  const handleActionEventsAndFeedbackChange = (changedValues, allValues) => {
    const originalActionTypeIDs = selectedNotification.actionEvents.map(
      (obj) => obj.id
    );
    const sameActionTypeIDs = allValues.actionEvents.every((id) =>
      originalActionTypeIDs.includes(id)
    );

    if (
      sameActionTypeIDs &&
      allValues.feedbackDescription === selectedNotification.description
    ) {
      setNotificationModified(false);
    } else if (!notificationModified) {
      setNotificationModified(true);
    }
  };

  const handleSaveNotification = () => {
    const formValues = form.getFieldsValue();

    const actionTypeIDs = formValues.actionEvents;
    const feedbackDescription = formValues.feedbackDescription;

    const actionEvents = actionTypeIDs.map((actionTypeID) => {
      return { action_type_id: actionTypeID };
    });

    let notification = { ...selectedNotification };
    notification.actionEvents = actionEvents;
    notification.feedback_description = feedbackDescription;

    dispatch(saveUserFeedback(notification, false)).then((response) => {
      if (response.status) {
        setNotificationModified(false);
        setNotificationEditMode(false);
        setNotificationHasFeedback(true);

        messageAPI.success(
          "Successfully saved changes to event action types and description"
        );
      } else {
        messageAPI.error(
          "Error occured when saving changes to event action types and description"
        );
      }
    });
  };

  const handleDeleteNotification = () => {
    dispatch(deleteNotification(selectedNotification.clip_id)).then(
      (result) => {
        if (result.status) {
          messageAPI.success("Event deleted");
          const nextNotification =
            eventListControl.current.getNextNotification();
          setMainNotification(nextNotification);
        } else {
          messageAPI.error("Unable to delete event");
        }
      }
    );
  };

  const getIntentLabelColor = (value) => {
    const intent = intentList.find((option) => option.value === value);
    return intent ? intent.color : "#FFFFFF";
  };

  const getIntentLabel = (value) => {
    const intent = intentList.find((option) => option.value === value);
    return intent ? intent.label : value;
  };

  const tagRender = ({ label, closable, onClose }) => {
    const actionType = actionTypes.find(
      (actionType) => actionType.name === label
    );
    const intent = actionType?.intent;
    const intentColor = getIntentLabelColor(intent);

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        bordered={false}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 6,
          fontSize: 14,
          fontWeight: 500,
          padding: 4,
          margin: 3,
          borderColor: intentColor,
          color: intentColor,
        }}
      >
        {label}
      </Tag>
    );
  };

  const loadingNotificationPlaceholder = (
    <div className="h-full flex flex-col gap-6">
      <Skeleton.Node
        key="loading-selected-notification-react-player"
        className="w-full"
        style={{ height: "40vh" }}
        active={true}
        children={<></>}
      />
      <Skeleton.Node
        key="loading-selected-notification-timestamp"
        className="w-full h-16"
        active={true}
        children={<></>}
      />
      <Skeleton.Node
        key="loading-selected-notification-edit"
        className="w-full"
        style={{ height: "40vh" }}
        active={true}
        children={<></>}
      />
    </div>
  );

  return (
    <div className="w-full h-full flex flex-col xl:flex-row gap-10 justify-between px-6 py-4">
      <div className="h-full w-3/5 2xl:w-1/2">
        {loadingNotification ? (
          loadingNotificationPlaceholder
        ) : selectedNotification ? (
          <div className="h-full flex flex-col gap-4">
            <div className="border-2 border-neutral-200 dark:border-neutral-900 rounded-xl overflow-hidden shadow-sm shadow-neutral-200 dark:shadow-neutral-800">
              <NotificationPlayer notification={selectedNotification} />
            </div>

            {/* Camera Name and Timestamp */}
            <div className="flex px-4 py-3 justify-between border border-neutral-200 dark:border-neutral-900 bg-white dark:bg-neutral-800 rounded-xl shadow-sm shadow-neutral-200 dark:shadow-neutral-800">
              <div className="flex gap-4">
                <h2 className="font-semibold text-neutral-600 dark:text-neutral-300">
                  {selectedNotification?.cameraname}
                </h2>
                <div className="flex">
                  {selectedNotification &&
                    selectedNotification.intentDetected !== "" &&
                    selectedNotification?.intentDetected
                      ?.split(", ")
                      .map((item, index, array) => {
                        const label =
                          index < array.length - 1
                            ? `${getIntentLabel(item)}`
                            : getIntentLabel(item);
                        return (
                          <Tag
                            key={index}
                            color={getIntentLabelColor(item)}
                            style={{
                              fontSize: 14,
                              lineHeight: 2,
                            }}
                          >
                            {label}
                          </Tag>
                        );
                      })}
                </div>
              </div>

              <h2 className="font-medium text-neutral-500 dark:text-neutral-400">
                {selectedNotification?.sent_date}
              </h2>
            </div>

            {/* Save, Edit, Delete Notification */}
            <div className="flex flex-1 flex-col border border-neutral-200 dark:border-neutral-900 bg-white dark:bg-neutral-800 rounded-xl shadow-sm shadow-neutral-200 dark:shadow-neutral-800">
              <div className="flex gap-4 px-4 py-2 border-b border-neutral-200 dark:border-neutral-900">
                <div className="flex gap-1 p-1 bg-neutral-100 dark:bg-neutral-700 rounded-lg">
                  <Button
                    type="text"
                    icon={<EditFilled />}
                    onClick={handleToggleEditNotificationMode}
                  />
                  <Button
                    type="text"
                    icon={<UndoOutlined />}
                    disabled={!(notificationEditMode && notificationModified)}
                    onClick={handleUndoNotificationChanges}
                  />
                  <Button
                    type="text"
                    icon={<SaveFilled />}
                    disabled={!(notificationEditMode && notificationModified)}
                    onClick={handleSaveNotification}
                  />
                </div>

                <div className="flex gap-1 p-1 bg-neutral-100 dark:bg-neutral-700 rounded-lg">
                  <Button
                    type="text"
                    icon={<LikeFilled />}
                    onClick={handleApproveNotification}
                    disabled={notificationEditMode || notificationHasFeedback}
                  />

                  <Button
                    type="text"
                    icon={<DislikeFilled />}
                    onClick={handleDisapproveNotification}
                    disabled={notificationEditMode || notificationHasFeedback}
                  />

                  <Popconfirm
                    title={
                      <p className="text-red-500 font-semibold">Delete Event</p>
                    }
                    description="Are you sure you want to delete this event?"
                    icon={
                      <WarningFilled
                        style={{
                          color: "red",
                        }}
                      />
                    }
                    onConfirm={handleDeleteNotification}
                    okText="Yes"
                    okButtonProps={{ danger: true }}
                    cancelText="No"
                  >
                    <Button type="text" icon={<DeleteFilled />} />
                  </Popconfirm>
                </div>
              </div>

              <Form
                form={form}
                layout="vertical"
                className="px-4 py-2"
                onValuesChange={handleActionEventsAndFeedbackChange}
              >
                <h3>Actions Detected</h3>
                <Form.Item name="actionEvents">
                  <Select
                    size="large"
                    mode="multiple"
                    className="w-full"
                    disabled={!notificationEditMode}
                    placeholder="No Actions Detected"
                    fieldNames={{
                      label: "name",
                      value: "id",
                    }}
                    options={actionTypes}
                    tagRender={tagRender}
                  />
                </Form.Item>

                <h3>Event Description</h3>
                <Form.Item name="feedbackDescription">
                  <Input.TextArea
                    className="rounded text-justify"
                    readOnly={!notificationEditMode}
                    variant={notificationEditMode ? "outlined" : "filled"}
                    autoSize={{ minRows: 9, maxRows: 9 }}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <h1 className="mt-10 text-center">
            No Events Found with Selected Filters
          </h1>
        )}
      </div>

      <div className="w-full min-w-96 md:w-1/2 xl:w-1/3">
        <EventList
          ref={eventListControl}
          setMainNotification={setMainNotification}
        />
      </div>

      {MessageContextHolder}
    </div>
  );
}
