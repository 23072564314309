import { cameraAction } from "./camera";
import cameraApi from "../../api/camera";
import { errorAction } from "../error/error";
import { notificationAction } from "../notification/notification";
import { setAlertMessage, setShowAlertMessage } from "../alert/alertAction";

export const getCameras = () => {
  return async (dispatch) => {
    try {
      let respose = await cameraApi.getCameras();

      if (respose.status) {
        dispatch(cameraAction.replaceCameras(respose.camera_list));
        dispatch(notificationAction.setCameraList(respose.camera_list));
      } else {
        respose = await cameraApi.getCameras();

        if (respose.status) {
          dispatch(cameraAction.replaceCameras(respose.camera_list));
          dispatch(notificationAction.setCameraList(respose.camera_list));
        } else {
          dispatch(errorAction.setErrorMessage(respose.error));
          dispatch(errorAction.openModal());
        }
      }
      if (respose.status) {
        let brokenCameras = [];

        respose.camera_list.forEach((camera) => {
          if (!camera.ishealthy) {
            brokenCameras.push(camera.name);
          }
        });

        if (brokenCameras.length) {
          let alertMessage = "Something seriously bad happened with the";
          alertMessage +=
            brokenCameras.length <= 1
              ? ` camera : ${brokenCameras[0]}.`
              : ` cameras: ${brokenCameras.join(", ")}.`;

          alertMessage +=
            " Please click <a id='myLink' href='#/cameraHelp' ><strong>here</strong></a> to see instruction about how to fix.";

          dispatch(setAlertMessage("Holy smokes!", alertMessage, "danger"));
          dispatch(setShowAlertMessage(true));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCamera = (originalCameraData, modifiedCameraData) => {
  let updatedCameraResponse;

  return async (dispatch) => {
    try {
      // Update camera except action types
      const cameraData = { ...modifiedCameraData };
      cameraData.action_types = originalCameraData.action_types;

      updatedCameraResponse = await cameraApi.updateCamera(cameraData);
    } catch (error) {
      dispatch(errorAction.setErrorMessage(error));
      dispatch(errorAction.openModal());
    }

    // Find newly added action types
    const newActionTypes = modifiedCameraData.action_types.filter(
      (modifiedActionType) =>
        !originalCameraData.action_types.find(
          (originalActionType) =>
            originalActionType.id === modifiedActionType.id
        )
    );

    // Find action types with updated intent
    const updatedActionTypes = modifiedCameraData.action_types.filter(
      (modifiedActionType) => {
        const originalActionType = originalCameraData.action_types.find(
          (actionType) => actionType.id === modifiedActionType.id
        );

        return (
          originalActionType &&
          originalActionType.intent !== modifiedActionType.intent
        );
      }
    );

    // Find deleted action types
    const deletedActionTypes = originalCameraData.action_types.filter(
      (originalActionType) =>
        !modifiedCameraData.action_types.find(
          (modifiedActionType) =>
            modifiedActionType.id === originalActionType.id
        )
    );

    console.log("New Action Types: ", newActionTypes);
    console.log("Updated Action Types: ", updatedActionTypes);
    console.log("Deleted Action Types: ", deletedActionTypes);

    // Add newly added action types
    if (newActionTypes.length > 0) {
      try {
        await cameraApi.addCameraActions(
          modifiedCameraData.mac,
          newActionTypes
        );
      } catch (error) {
        dispatch(errorAction.setErrorMessage(error));
        dispatch(errorAction.openModal());
      }
    }

    // Update action types with updated intent
    if (updatedActionTypes.length > 0) {
      try {
        await cameraApi.updateCameraActions(
          modifiedCameraData.mac,
          updatedActionTypes
        );
      } catch (error) {
        dispatch(errorAction.setErrorMessage(error));
        dispatch(errorAction.openModal());
      }
    }

    // Delete action types
    if (deletedActionTypes.length > 0) {
      try {
        await cameraApi.deleteCameraActions(
          modifiedCameraData.mac,
          deletedActionTypes
        );
      } catch (error) {
        dispatch(errorAction.setErrorMessage(error));
        dispatch(errorAction.openModal());
      }
    }

    updatedCameraResponse.result.action_types = modifiedCameraData.action_types;
    dispatch(cameraAction.updateCamera(updatedCameraResponse.result));
    return updatedCameraResponse.status;
  };
};

export const removeCamera = (camera) => {
  return async (dispatch) => {
    try {
      const respose = await cameraApi.removeCamera(camera);
      if (respose.status) {
        dispatch(cameraAction.removeCameras(camera));
      } else {
        dispatch(errorAction.setErrorMessage(respose.error));
        dispatch(errorAction.openModal());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCameraHealthy = (mac, ishealthy) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setCameraHealthy({ mac, ishealthy }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addCamera = (camera, isFromScanList = false) => {
  return async (dispatch) => {
    try {
      let result = false;

      const respose = await cameraApi.addCamera(camera);

      if (isFromScanList) {
        dispatch(
          cameraAction.setScanCameraStatus({
            mac: camera.mac,
            status: respose.status,
          })
        );
      }
      if (respose.status) {
        dispatch(cameraAction.addCamera(respose.result));
        if (!isFromScanList) {
          cameraApi.restartServices();
        }
        result = true;
      } else {
        dispatch(errorAction.setErrorMessage(respose.error));
        dispatch(errorAction.openModal());
      }
      return result;
    } catch (err) {
      console.log(err);
    }
  };
};

export const scanNetwork = () => {
  return async (dispatch) => {
    try {
      const respose = await cameraApi.scanNetwork();

      if (respose.status) {
        dispatch(cameraAction.setScanCamera(respose.scanCamera_list));
      } else {
        dispatch(errorAction.setErrorMessage(respose.error));
        dispatch(errorAction.openModal());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetScanCamera = () => {
  return async (dispatch) => {
    try {
      dispatch(cameraAction.setScanCamera(null));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setScanCameraUsername = (mac, username) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setScanCameraUsername({ mac, username }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSelectScanCamera = (index, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setSelectScanCamera({ index, value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSelectScanCameraAll = (value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setSelectScanCameraAll({ value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeScanCamera = (mac) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.removeScanCamera(mac));
    } catch (err) {
      console.log(err);
    }
  };
};

export const restartServices = () => {
  return async (dispatch) => {
    try {
      await cameraApi.restartServices();
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInScanCamera = (index, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setLoadingInScanCamera({ index, value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInCameraByMac = (mac, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setLoadingInCameraByMac({ mac, value }));
    } catch (err) {
      console.log(err);
    }
  };
};
