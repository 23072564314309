import { useEffect, useState } from "react";

import { Skeleton, Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import salesAPI from "../../api/sales";
import authApi from "../../api/auth";

export default function SalesVideoPlayer({ cameras = [], timestamp = null }) {
  const [playbackData, setPlaybackData] = useState([]);
  const [loadingPlaybackData, setLoadingPlaybackData] = useState(false);

  useEffect(() => {
    const fetchSalesFootage = async () => {
      if (cameras.length > 0 && timestamp) {
        setLoadingPlaybackData(true);
        const response = await salesAPI.getSalesTransactionFootage(
          cameras,
          timestamp
        );
        setLoadingPlaybackData(false);

        if (response.status) {
          setPlaybackData(response.data);
        }
      }
    };

    fetchSalesFootage();
  }, [cameras, timestamp]);

  return (
    <div className="h-full flex flex-col gap-8 px-5 py-8 overflow-y-scroll">
      {loadingPlaybackData &&
        playbackData.length === 0 &&
        cameras.map((camera) => {
          return (
            <Skeleton.Node
              key={`loading-${camera.name}`}
              className="w-full h-56"
              active={true}
              children={<></>}
            />
          );
        })}

      {playbackData &&
        playbackData.length > 0 &&
        playbackData.map((camera) => {
          return (
            <div
              key={`playback-${camera.name}-div`}
              className="flex flex-col gap-4 px-5 py-4 border border-neutral-300 dark:border-neutral-600 bg-neutral-200 dark:bg-neutral-700 shadow-md dark:shadow-neutral-700/50 rounded-md"
            >
              <h3 className="text-lg">{camera.name}</h3>
              {!camera.file && (
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="error"
                  style={{
                    fontSize: 14,
                    lineHeight: 2,
                    marginInlineEnd: 0,
                  }}
                >
                  Video Footage Not Found for Sales Transaction
                </Tag>
              )}

              <div
                key={`react-player-${camera.mac}-div`}
                className="border dark:border-neutral-500 rounded-md overflow-hidden"
              >
                <ReactPlayer
                  key={`react-player-${camera.mac}`}
                  height="auto"
                  width="100%"
                  url={camera.url}
                  controls
                  muted={true}
                  loop={false}
                  playbackRate={1}
                  preload="auto"
                  autoPlay={false}
                  config={{
                    file: {
                      forceHLS: true,
                      hlsOptions: {
                        xhrSetup: async (xhr) => {
                          const token = await authApi.getAccessToken;
                          xhr.setRequestHeader(
                            "Authorization",
                            `Bearer ${token.customToken}`
                          );
                        },
                        capLevelToPlayerSize: true,
                        maxBufferLength: 60,
                        maxMaxBufferLength: 30,
                        maxBufferSize: 60 * 1000 * 1000,
                        maxBufferHole: 2.5,
                        highBufferWatchdogPeriod: 10,
                        stretchShortVideoTrack: true,
                        maxFragLookUpTolerance: 2.5,
                        enableWorker: true,
                        lowLatencyMode: true,
                        backBufferLength: 90,
                        progressive: true,
                        startLevel: -1,
                        startPosition: 0,
                        testBandwidth: true,
                        liveDurationInfinity: false,
                        fragLoadingTimeOut: 20000,
                        manifestLoadingMaxRetry: 10,
                        levelLoadingMaxRetry: 10,
                        appendErrorMaxRetry: 10,
                        preferManagedMediaSource: true,
                        enableSoftwareAES: true,
                        frontBufferFlushThreshold: Infinity,
                        autoStartLoad: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
}
