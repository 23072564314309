import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  Divider,
  Drawer,
  Empty,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import {
  CaretRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { PiCashRegister as CashRegisterOutlined } from "react-icons/pi";
import { LuCameraOff as CameraOffFilled } from "react-icons/lu";
import moment from "moment";

import EditRegistersModal from "../components/EditRegistersModal";
import SalesVideoPlayer from "../components/SalesVideoPlayer";
import salesAPI from "../api/sales";
import registersAPI from "../api/registers";

export default function Sales() {
  const cameras = useSelector((state) => state.camera.cameraList);

  const [salesTransactions, setSalesTransactions] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [fetchSalesTransactions, setFetchSalesTransactions] = useState(true);
  const [fetchAllRegisters, setFetchAllRegisters] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [registerCameras, setRegisterCameras] = useState([]);

  const [openEditRegistersModal, setOpenEditRegistersModal] = useState(false);
  const [openVideoClipsDrawer, setOpenVideoClipsDrawer] = useState(false);

  const [scrollY, setScrollY] = useState("calc(78vh - 10rem)");
  const [tableHeight, setTableHeight] = useState("78vh");

  useEffect(() => {
    const fetchSalesTransactions = async () => {
      if (fetchSalesTransactions) {
        setFetchSalesTransactions(false);
        const response = await salesAPI.getAllSalesTransactions();

        if (response.status) {
          setSalesTransactions(response.data);
        }
      }
    };

    const fetchAllRegisters = async () => {
      if (fetchAllRegisters) {
        setFetchAllRegisters(false);
        const response = await registersAPI.getAllRegisters();
        if (response.status) {
          setRegisters(response.data);
        }
      }
    };

    const updateTableProperties = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1374) {
        // desktop
        setTableHeight("78vh");
        setScrollY("calc(78vh - 10rem)");
      } else if (windowWidth >= 768) {
        // tablets
        setTableHeight("70vh");
        setScrollY("calc(70vh - 10rem)");
      } else {
        // mobile
        setTableHeight("70vh");
        setScrollY("calc(70vh - 2rem)");
      }
    };

    fetchSalesTransactions();
    fetchAllRegisters();

    updateTableProperties();
    window.addEventListener("resize", updateTableProperties);
    return () => window.removeEventListener("resize", updateTableProperties);
  }, [fetchSalesTransactions, fetchAllRegisters]);

  const updateRegisterCameras = async (initialRegisters, modifiedRegisters) => {
    setOpenEditRegistersModal(false);

    const registerCamerasChanges = {};
    modifiedRegisters.forEach((modifiedRegister) => {
      const initialRegister = initialRegisters.find(
        (item) => item.id === modifiedRegister.id
      );

      if (initialRegister) {
        const addedCameras = modifiedRegister.cameras.filter(
          (camera) => !initialRegister.cameras.includes(camera)
        );
        const deletedCameras = initialRegister.cameras.filter(
          (camera) => !modifiedRegister.cameras.includes(camera)
        );

        if (addedCameras.length > 0 || deletedCameras.length > 0) {
          registerCamerasChanges[modifiedRegister.id] = {
            linked: addedCameras,
            unlinked: deletedCameras,
          };
        }
      }
    });

    for (const [registerID, cameraMACs] of Object.entries(
      registerCamerasChanges
    )) {
      const linkedCameraMACs = cameraMACs.linked;
      const unlinkedCameraMACs = cameraMACs.unlinked;

      linkedCameraMACs.forEach(async (cameraMAC) => {
        await registersAPI.linkCameraToReigster(registerID, cameraMAC);
      });

      unlinkedCameraMACs.forEach(async (cameraMAC) => {
        await registersAPI.linkCameraToReigster(registerID, cameraMAC);
      });
    }

    setFetchAllRegisters(true);
  };

  const getTransactionRegisterCameras = (transaction) => {
    const registerID = transaction.register.id;

    const registerData = registers.find(
      (register) => register.id === registerID
    );

    const registerCamerasList = registerData
      ? registerData.register_cameras
      : [];

    const registerCameraMACs = registerCamerasList
      ? registerCamerasList.map((registerCamera) => {
          return registerCamera.camera_mac;
        })
      : [];

    if (cameras) {
      const transactionRegisterCameras = cameras.filter((camera) =>
        registerCameraMACs.includes(camera.mac)
      );

      setRegisterCameras(transactionRegisterCameras);
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: 100,
      fixed: "left",
    },
    {
      title: "Register",
      dataIndex: "register",
      key: "register",
      width: 120,
      align: "center",
      render: (register) => register.name,
    },
    {
      title: "Date and Time",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (value) => (
        <div className="flex flex-wrap gap-1 items-center">
          {moment(value, "YYYY-MM-DD HH:mm:ss").format("MMMM Do YYYY")}
          <div className="dot"></div>
          {moment(value, "YYYY-MM-DD HH:mm:ss").format("h:mm A")}
        </div>
      ),
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        moment(a.timestamp, "YYYY-MM-DD HH:mm:ss").valueOf() -
        moment(b.timestamp, "YYYY-MM-DD HH:mm:ss").valueOf(),
    },
    {
      title: "Order Status",
      dataIndex: "status",
      key: "order_status",
      render: (status) => {
        switch (status) {
          case "Completed":
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {status}
              </Tag>
            );
          case "Returned":
            return (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                {status}
              </Tag>
            );
          case "Disputed":
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                {status}
              </Tag>
            );
          default:
            return <Tag>{status}</Tag>;
        }
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "order_total",
      width: 100,
      render: (num) => <>${num.toFixed(2)}</>,
      sorter: (a, b) => a.order_total - b.order_total,
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "View Related Video Footage",
      dataIndex: "actions",
      key: "actions",
      width: 160,
      fixed: "right",
      render: (_, transaction) => (
        <Button
          shape="circle"
          icon={<CaretRightOutlined />}
          onClick={() => {
            setOpenVideoClipsDrawer(true);
            getTransactionRegisterCameras(transaction);
            setSelectedTransaction(transaction);
          }}
        />
      ),
    },
  ];

  return (
    <div className="page-container flex flex-col gap-4">
      <h1 className="page-header">Sales</h1>

      <div className="flex flex-row gap-8">
        <Upload
          className="flex flex-col gap-2 w-52"
          accept=".csv,.xls,.xlsx"
          customRequest={async ({ onSuccess, onError, file }) => {
            const response = await salesAPI.uploadSalesTransactions(
              "provider",
              file
            );

            if (response.status) {
              onSuccess("File uploaded succcesfully.");
              message.success(`File uploaded successfully.`);
              setFetchSalesTransactions(true);
            } else {
              const error = new Error(response.error);
              onError({ error }, "File upload failed");
              message.error(response.error);
            }
          }}
        >
          <Button type="primary" icon={<UploadOutlined />} iconPosition="end">
            Upload CSV or XLS Files
          </Button>
        </Upload>

        <Button
          type="primary"
          icon={<CashRegisterOutlined size={18} />}
          iconPosition="end"
          onClick={() => setOpenEditRegistersModal(true)}
        >
          Manage Registers
        </Button>
      </div>

      <div>
        <Table
          sticky
          bordered
          className="tables"
          style={{ width: "100%", height: tableHeight }}
          scroll={{ x: "max-content", y: scrollY }}
          pagination={{
            position: ["bottomCenter"],
          }}
          columns={columns}
          dataSource={salesTransactions}
          rowKey="order_id"
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"No sales transcations found."}
              />
            ),
          }}
        />
      </div>

      <Drawer
        classNames={{ body: "p-0 overflow-hidden h-full" }}
        width={450}
        title="Related Video Footage"
        open={openVideoClipsDrawer}
        onClose={() => {
          setOpenVideoClipsDrawer(false);
          setSelectedTransaction(null);
          setRegisterCameras([]);
        }}
      >
        <div className="h-full flex flex-col bg-neutral-50 dark:bg-neutral-950">
          {selectedTransaction && (
            <div className="flex flex-col gap-6 p-6 border-b dark:border-neutral-700 shadow-sm bg-white dark:bg-neutral-600">
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 text-lg text-neutral-400">
                  <DollarCircleOutlined /> Sales Transcation
                </div>
                <h1>{selectedTransaction.order_id}</h1>
              </div>

              <div>
                <h3>Summary</h3>
                <Divider />

                <div className="flex flex-col gap-1 mt-3">
                  <div className="flex justify-between">
                    <h4>Transaction ID</h4>
                    <div>
                      {moment(
                        selectedTransaction.timestamp,
                        "YYYY-MM-DD HH:mm:ss"
                      ).format("MMMM Do YYYY | h:mm A")}
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <h4>Status</h4>
                    <div>{selectedTransaction.status}</div>
                  </div>

                  <div className="flex justify-between">
                    <h4>Total</h4>
                    <div>${selectedTransaction.total}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!cameras && (
            <Empty
              className="py-5 bg-transparent"
              image={<CameraOffFilled className="text-neutral-200 text-8xl" />}
              description={<h3>No Cameras Found</h3>}
            />
          )}

          {cameras && registerCameras.length === 0 && selectedTransaction && (
            <Empty
              className="py-5"
              image={<CameraOffFilled className="text-neutral-200 text-8xl" />}
              description={
                <h3>
                  No Cameras Linked to {selectedTransaction.register.name}
                </h3>
              }
            />
          )}

          {registerCameras.length > 0 && selectedTransaction && (
            <div className="h-full">
              <SalesVideoPlayer
                cameras={registerCameras}
                timestamp={moment(
                  selectedTransaction.timestamp,
                  "YYYY-MM-DD HH:mm:ss"
                ).toDate()}
              />
            </div>
          )}
        </div>
      </Drawer>
      <EditRegistersModal
        registers={registers}
        open={openEditRegistersModal}
        onOk={updateRegisterCameras}
        onCancel={() => setOpenEditRegistersModal(false)}
      />
    </div>
  );
}
