import React from "react";
import ReportIcon from "@mui/icons-material/Report";
import LiveVideoPlayer from "../LivePlayer";

const VideoList = React.memo(({ cameraList, createVideoUrl, setMainVideo }) => {
  return (
    <div className="h-full flex flex-row flex-wrap justify-center overflow-scroll">
      {cameraList?.map((camera, index) => {
        const videoUrl = createVideoUrl(camera.mac);
        return (
          <div className="video-list-card m-4 overflow-hidden" key={index}>
            <div>
              <h3 className="video-list-header py-2">{camera.name}</h3>
              {!camera.ishealthy && (
                <div
                  className="text-red-700 p-px"
                  title={`Camera: ${camera.name} has a issue.`}
                >
                  <ReportIcon />
                </div>
              )}
            </div>
            <div
              className="h-32 aspect-video overflow-hidden"
              onClick={(e) => setMainVideo(camera)}
            >
              <LiveVideoPlayer
                playerId={`player_${camera.mac}`}
                videoUrrl={videoUrl}
                cameraName={camera.name}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default VideoList;
